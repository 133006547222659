<template>
  <b-modal
      ref="edit-modal"
      id="editModal"
      size="xl"
      hide-header
      hide-footer
  >
    <h4 class="mb-4">{{ formTitle }}</h4>
    <b-row>
      <b-col cols="12" lg="4" ></b-col>
      <b-col cols="12" lg="4" v-if="user.user_rule !== 'vector'">
        <h6>Vettore</h6>
        <VectorSelect ref="vectorSelect" @setSelect="(value) => editedItem.vector_id = value" />
      </b-col>
      <b-col cols="12" lg="4" v-else></b-col>
      <b-col cols="12" lg="4" >
        <h6>Scegli tra </h6>
        <b-form-select
            v-model="editedItem.parent_type"
            :options="[
                         {value:'clinic', text: 'Clinica'},
                         {value:'caregiver', text: 'Caregiver'},
                         {value:'', text: 'Paziente'}
                     ]"
            @change="resetWizard" size="lg">
        </b-form-select>
      </b-col>
    </b-row>
    <form-wizard
        @on-complete="onComplete"
        @on-loading="setLoading"
        @on-validate="handleValidation"
        @on-error="handleErrorMessage"
        color="#1e88e5"
        :title="null"
        :subtitle="null"
        step-size="xs"
        ref="formWizard2"
    >
      <tab-content title="Paziente"
                   :key="0"
                   icon="mdi mdi-account-settings-variant"
                   :before-change="validatePatient">
        <b-row>
          <!-- name -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Name e Cognome *</h6>
            <b-form-input
                v-model="editedItem.patient.name"
                placeholder="Inserisci Nome"
                name="name-group"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- email -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Email</h6>
            <b-form-input
                v-model="editedItem.patient.email"
                placeholder="Inserisci email"
                name="name-group"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- dob -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Data di nascita</h6>
            <b-form-datepicker
                id="datepicker-full-width"
                v-model="editedItem.patient.dob"
                placeholder="Seleziona Data"
                menu-class="w-100"
                calendar-width="100%"
                :min="new Date((new Date()).getFullYear()-120, (new Date()).getMonth(), (new Date()).getDay() )"
                :max="new Date((new Date()).getFullYear()-18, (new Date()).getMonth(), (new Date()).getDay() )"
                class="mb-2"
                :hide-header="false"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="it"
                size="lg"
            ></b-form-datepicker>
          </b-col>
          <!-- cod. fiscale -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Codice Fiscale</h6>
            <b-form-input
                v-model="editedItem.patient.fiscal_code"
                placeholder="Lunghezza massima 16 caratteri"
                name="name-group"
                :maxlength="16"
                @keyup="fiscalCode"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- sesso -->
          <b-col cols="12" lg="3" class="mb-3">
            <b-form-group v-slot="{ ariaDescribedby }">
              <h6>Sesso</h6>
              <b-form-radio-group
                  v-model="editedItem.patient.gender"
                  :options="[
                    { text: 'Uomo', value: 'man' },
                    { text: 'Donna', value: 'woman' },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <!-- tel -->
          <b-col cols="12" lg="3" class="mb-3">
            <h6>Numero di telefono</h6>
            <b-form-input type="tel" v-model="editedItem.patient.phone_number" placeholder="123 4564055"></b-form-input>
          </b-col>
          <!-- tags -->
          <b-col cols="12" lg="6" class="mb-3">
            <SelectTag ref="selectTagPatient" @setSelect="(value) => editedItem.patient.tags = value"/>
          </b-col>
          <!-- gmaps -->
          <b-col cols="12" lg="12" class="mb-3">
            <h6>Indirizzo di residenza *</h6>
            <GMapAutocompletePatient @setPlace="(value) => editedItem.patient.residence_address = value" />
          </b-col>
          <!-- note -->
          <b-col cols="12" lg="12" class="mb-3">
            <h6>Note</h6>
            <b-form-textarea
                id="textarea-auto-height"
                rows="3"
                max-rows="8"
                v-model="editedItem.patient.notes"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Caregiver"
                   :key="1"
          v-if="editedItem.parent_type === 'caregiver'"
          :before-change="validateCaregiver"
          icon="mdi mdi-car">
        <b-row>
          <!-- name -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Name e Cognome *</h6>
            <b-form-input
                v-model="editedItem.caregiver.name"
                placeholder="Inserisci Nome"
                name="name-group"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- email -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Email</h6>
            <b-form-input
                v-model="editedItem.caregiver.email"
                placeholder="Inserisci email"
                name="name-group"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- dob -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Data di nascita</h6>
            <b-form-datepicker
                id="datepicker-full-width"
                v-model="editedItem.caregiver.dob"
                placeholder="Seleziona Data"
                menu-class="w-100"
                calendar-width="100%"
                :min="new Date((new Date()).getFullYear()-120, (new Date()).getMonth(), (new Date()).getDay() )"
                :max="new Date((new Date()).getFullYear()-18, (new Date()).getMonth(), (new Date()).getDay() )"
                class="mb-2"
                :hide-header="false"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="it"
                size="lg"
            ></b-form-datepicker>
          </b-col>
          <!-- cod. fiscale -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Codice Fiscale</h6>
            <b-form-input
                v-model="editedItem.caregiver.fiscal_code"
                placeholder="Lunghezza massima 16 caratteri"
                name="name-group"
                :maxlength="16"
                @keyup="fiscalCode"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- sesso -->
          <b-col cols="12" lg="3" class="mb-3">
            <b-form-group v-slot="{ ariaDescribedby }">
              <h6>Sesso</h6>
              <b-form-radio-group
                  v-model="editedItem.caregiver.gender"
                  :options="[
                    { text: 'Uomo', value: 'man' },
                    { text: 'Donna', value: 'woman' },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <!-- tel -->
          <b-col cols="12" lg="3" class="mb-3">
            <h6>Numero di telefono</h6>
            <b-form-input type="tel" v-model="editedItem.caregiver.phone_number" placeholder="123 4564055"></b-form-input>
          </b-col>
          <!-- tags -->
          <b-col cols="12" lg="6" class="mb-3">
            <SelectTag ref="selectTag" @setSelect="(value) => editedItem.caregiver.tags = value" />
          </b-col>
          <!-- gmaps -->
          <b-col cols="12" lg="12" class="mb-3">
            <h6>Indirizzo di residenza</h6>
            <GMapAutocompleteCaregiver @setPlace="(value) => editedItem.caregiver.residence_address = value" />
          </b-col>
          <!-- note -->
          <b-col cols="12" lg="12" class="mb-3">
            <h6>Note</h6>
            <b-form-textarea
                id="textarea-auto-height"
                rows="3"
                max-rows="8"
                v-model="editedItem.caregiver.notes"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Clinica"
                   :key="2"
          v-else-if="editedItem.parent_type === 'clinic'"
          :before-change="validateClinic"
          icon="mdi mdi-car">
        <b-row>
          <!-- company name -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Ragione Sociale *</h6>
            <b-form-input
                v-model="editedItem.clinic.company_name"
                placeholder="Inserisci la Ragione Sociale"
                name="name-group"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- email -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Email</h6>
            <b-form-input
                v-model="editedItem.clinic.email"
                placeholder="Inserisci email"
                name="name-group"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- vat number -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>Partita Iva</h6>
            <b-form-input
                v-model="editedItem.clinic.vat_number"
                placeholder=""
                name="name-group"
                :maxlength="11"
                @keyup="fiscalCode"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- sdi -->
          <b-col cols="12" lg="6" class="mb-3">
            <h6>SDI</h6>
            <b-form-input
                v-model="editedItem.clinic.sdi"
                placeholder=""
                name="name-group"
                :maxlength="10"
                size="lg"
            ></b-form-input>
          </b-col>
          <!-- tel -->
          <b-col cols="12" lg="3" class="mb-3">
            <h6>Numero di telefono</h6>
            <b-form-input type="tel" v-model="editedItem.clinic.phone_number" placeholder="123 4564055"></b-form-input>
          </b-col>
          <!-- tags -->
          <b-col cols="12" lg="6" class="mb-3">
            <SelectTag ref="selectTag" @setSelect="(value) => editedItem.clinic.tags = value" />
          </b-col>
          <!-- gmaps -->
          <b-col cols="12" lg="12" class="mb-3">
            <h6>Indirizzo di residenza *</h6>
            <GMapAutocompleteClinic @setPlace="(value) => editedItem.clinic.address = value" />
          </b-col>
          <!-- note -->
          <b-col cols="12" lg="12" class="mb-3">
            <h6>Note</h6>
            <b-form-textarea
                id="textarea-auto-height"
                rows="3"
                max-rows="8"
                v-model="editedItem.clinic.notes"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Conferma" icon="mdi mdi-check" :key="3">
        <h4 class="font-weight-bold text-center">
          Sicuro dei dati inseriti? Clicca su Salva per completare
        </h4>
      </tab-content>

      <div class="loader" v-if="loadingWizard"></div>
      <div v-if="errorMsg">
        <span class="error error--text" style="color: red">{{ errorMsg }}</span>
      </div>

      <b-button variant="danger" dark slot="prev">
        <i class="mdi mdi-chevron-left mr-1"></i>
        Indietro
      </b-button>
      <b-button ref="wizard_next" variant="primary" slot="next">
        Avanti
        <i class="mdi mdi-chevron-right ml-1"></i>
      </b-button>
      <b-button variant="success" slot="finish">
        Salva
        <i class="mdi mdi-check ml-1"></i>
      </b-button>
    </form-wizard>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "PatientNewPatient",
  components: {
    FormWizard,
    TabContent,
    SelectTag: () => import('@/views/tags/components/SelectTags'),
    VectorSelect: () => import('@/views/users/components/VectorsSelect'),
    GMapAutocompleteClinic: () => import("@/components/gmaps/Autocomplete"),
    GMapAutocompleteCaregiver: () => import("@/components/gmaps/Autocomplete"),
    GMapAutocompletePatient: () => import("@/components/gmaps/Autocomplete"),
  },
  data: () => ({
    formTitle: 'Nuovo Paziente',
    count_checkbox_caregiver: 0,
    caregiver_residence_address_event: '',
    patient_residence_address_event: '',
    selected_tab_wizard: 0,
    checkbox_caregiver: false,
    loadingWizard: false,
    errorMsg: null,
    autoCompleteOptions: {
      componentRestrictions: {
        country: ['IT'],
      },
    },
    editedItem: {
      vector_id: 0,
      parent_type: 'caregiver',
      caregiver: {
        id: "",
        name: '',
        email: '',
        fiscal_code: '',
        gender: 'man',
        phone_number: '',
        residence_address: '',
        tags: '',
        notes: '',
        dob: '',
      },
      clinic: {
        id: "",
        company_name: '',
        email: '',
        vat_number: '',
        sdi: '',
        phone_number: '',
        address: '',
        tags: '',
        notes: '',
        dob: '',
      },
      patient: {
        id: "",
        name: '',
        email: '',
        fiscal_code: '',
        gender: 'man',
        phone_number: '',
        residence_address: '',
        tags: '',
        notes: '',
        dob: '',
      }
    },
    defaultItem: {
      is_caregiver: true,
      caregiver: {
        id: "",
        authinfo: {
          name: '',
          email: '',
          password: '',
        },
        fiscal_code: '',
        gender: 'man',
        phone_number: '',
        residence_address: '',
        tags: '',
        notes: '',
        dob: '',
      },
      patient: {
        id: "",
        authinfo: {
          name: '',
          email: '',
          password: '',
        },
        fiscal_code: '',
        gender: 'man',
        phone_number: '',
        residence_address: '',
        tags: '',
        notes: '',
        dob: '',
      }
    },
  }),
  methods: {
    async getListPatient() {
      await this.$store.dispatch("setDataTable", [])
      const res = await this.axios.get('patients/')
      await this.$store.dispatch("setDataTable", res.data)
    },
    fiscalCode() {
      this.editedItem.patient.fiscal_code = this.editedItem.patient.fiscal_code.toUpperCase()
      this.editedItem.caregiver.fiscal_code = this.editedItem.caregiver.fiscal_code.toUpperCase()
    },
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      this.selected_tab_wizard = tabIndex;
      if ((tabIndex+1) > 0) {
        this.checkbox_caregiver = false
      } else {
        this.checkbox_caregiver = false
      }
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    validateCaregiver: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.editedItem.caregiver.name
          ) {
            reject(
                "Inserisci i campi obbligatori evidenziati con *"
            );
          } else {
            resolve(true);
          }
        }, 300);
      });
    },
    validateClinic: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.editedItem.clinic.name &&
              !this.editedItem.clinic.address
          ) {
            reject(
                "Inserisci i campi obbligatori evidenziati con *"
            );
          } else {
            resolve(true);
          }
        }, 300);
      });
    },
    validatePatient: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (!this.editedItem.patient.name &&
              !this.editedItem.patient.residence_address
          ) {
            reject(
                "Inserisci i campi obbligatori evidenziati con *"
            );
          } else {
            resolve(true);
          }
        }, 300);
      });
    },
    resetWizard() {
      this.$refs.formWizard2.reset()
      if (!(this.editedItem.parent_type !== '')) {
          const that = this
          setTimeout(function (){
            that.$refs.wizard_next.click()
          }, 300)
      }
    },
    async onComplete() {
      if (this.user.user_rule === 'vector') {
        this.editedItem.vector_id = this.user.user.id
      }

      const res = await this.axios.post('patient', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })

      this.$store.state.patientSelected = res.data.patient.id
      this.$refs["edit-modal"].hide();
      this.axios.get('patients').then(res => {
        this.$store.dispatch("setPatientSelect", res.data)
      })

      this.editedItem = this.defaultItem
      await this.getListPatient();
    },
  },
  computed: {
    user() {
      return this.$store.state.authentication;
    }
  }
}
</script>

<style>
.pac-container {
  z-index: 999999;
}
</style>